import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScItemCard } from './styled';
import { TabsData } from '../../types/tabs';

export const ItemCard: FC<TabsData> = ({ image, name, category, id }) => {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`${id}`);
    };

    return (
        <ScItemCard onClick={onClick}>
            <div className='img-wrp'>
                <img src={image} alt='' />
            </div>
            <div className='title'>{name}</div>
            <div className='category'>{category}</div>
            <div className='button'>Read more</div>
        </ScItemCard>
    );
};
