import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { TabsData } from '../types/tabs';

interface IDataProps {
    data: TabsData[];
}

const initialContextValue: IDataProps = {
    data: [],
};

export const DataContext = createContext<IDataProps>(initialContextValue);

interface DataProps {
    children: ReactNode;
}

export const DataProvider: FC<DataProps> = ({ children }) => {
    const [data, setData] = useState<TabsData[]>([]);

    const getData = async () => {
        const response = await fetch('data.json');
        const jsonData = await response.json();
        setData(jsonData);
    };

    useEffect(() => {
        getData();
    }, []);

    const value = useMemo(
        () => ({
            data,
        }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
