import { FC } from 'react';

interface IconProps {
    icon: string;
    weight?: number;
    color?: string;
    size?: string;
    className?: string;
    onClick?: () => void;
}

export const Icon: FC<IconProps> = ({ icon, weight = 400, color = '#fff', size = '20px', ...res }) => {
    return (
        <span
            style={{
                fontFamily: 'Material Symbols Rounded',
                fontSize: size,
                fontVariationSettings: `"FILL" 0, "wght" ${weight}, "GRAD" 0, "opsz" 48`,
                color,
            }}
            {...res}
        >
            {icon}
        </span>
    );
};
