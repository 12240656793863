import { FC, useContext, useState } from 'react';
import { TabsButtons } from '../../components/Tabs/TabsButtons';
import { Category } from '../../types/tabs';
import { Content } from '../../components/Content';
import { Tabs } from '../../components/Tabs';
import { DataContext } from '../../context/dataContext';

import logo from '../../assets/logo.png';

export const Home: FC = () => {
    const [selectedCategory, setSelectedCategory] = useState<Category>(Category.Mocltail);
    const { data } = useContext(DataContext);

    return (
        <>
            <div className='header'>
                <div className='logo'>
                    <img alt='' src={logo} />
                </div>
            </div>
            <TabsButtons onSelect={setSelectedCategory} selectedCategory={selectedCategory} />
            <Content>
                <Tabs data={data} category={selectedCategory} />
            </Content>
        </>
    );
};
