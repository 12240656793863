import { FC, useContext, useMemo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataContext } from '../../context/dataContext';
import { ScItemCard } from './styled';
import { Icon } from '../../components/Icon';

export const ItemPage: FC = () => {
    const { id } = useParams();
    const { data } = useContext(DataContext);
    const navigate = useNavigate();
    const [videoOpacity, setVideoOpacity] = useState(1);  // Состояние для прозрачности видео

    const item = useMemo(() => {
        if (!data) return null;
        return data.find((item) => item.id === id);
    }, [data, id]);

    const onGoBack = () => {
        navigate('/');
    };

    // Обработчик для изменения прозрачности в зависимости от скролла
    useEffect(() => {
        const handleScroll = () => {
            // Получаем текущую позицию скролла
            const scrollY = window.scrollY;
            // Рассчитываем новое значение opacity (от 1 до 0.3)
            const newOpacity = Math.max(0.3, 1 - scrollY / 500);  // Можно изменить делитель для более быстрого изменения
            setVideoOpacity(newOpacity);
        };

        // Добавляем обработчик скролла
        window.addEventListener('scroll', handleScroll);

        // Убираем обработчик при размонтировании компонента
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return item ? (
        <ScItemCard style={{ background: 'rgb(35, 31, 32)' }}>
            <div className='content'>
                <div className='control'>
                    <Icon onClick={onGoBack} icon='arrow_back' color='#fff' />
                </div>
                {/* Устанавливаем значение opacity в стиле видео */}
                <video
                    width='640'
                    height='360'
                    autoPlay
                    muted
                    style={{ opacity: videoOpacity }}
                >
                    <source src={item.video} type='video/mp4' />
                    Your browser does not support the video tag.
                </video>
                {/* <img src={item.fullImage} alt='' /> */}
                <div className='content-box'>
                    <h2>{item.name}</h2>
                    <span>{item.category}</span>
                    <p>{item.description}</p>
                    <p>{item.fullDescription.replace('Mojito', item.name)}</p>
                </div>
            </div>
        </ScItemCard>
    ) : null;
};
