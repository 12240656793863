import styled from 'styled-components';

export const ScTabs = styled.div`
    width: 100vw;
    margin: 0 auto 16px;
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    overflow: auto;
    padding: 16px;
    position: relative;
    background-color: #F9F9F9;

    &::before {
        position: absolute;
        content: '';
        left: -16px;
        right: -16px;
        bottom: 0;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
    }
`;

export const ScTabButton = styled.button<{ isSoon?: boolean }>`
    text-transform: capitalize;
    background: #cadeef;
    border-radius: 4px;
    border: none;
    color: #231f20;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 400;
    &:disabled {
        background: #E7F3FD;
        color: #39ACE7;
    }

    ${({ isSoon }) =>
        isSoon &&
        `
        opacity: .3;
        background: #CADEEF60;
        position: relative;
        color: rgba(0,0,0,.2);

        &:before{
            position: absolute;
            content: 'soon';
            background: #fff3;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            color:#231F20;
            border-radius: 4px;
            backdrop-filter: blur(1px);
            padding: 8px 16px;
        }
    `}
`;

export const ScTabContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;
