import styled from 'styled-components';

export const ScItemCard = styled.div`
    .content {
        width: 100%;
        margin: 0 auto;
        background: #000;
        color: #fff;
        box-sizing: border-box;
        position: relative;

        &::before {
            position: fixed;
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background:  rgb(35, 31, 32);
            z-index: 2;
        }

        video {
            width: 100%;
            height: auto;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 3;
        }

        .control {
            width: calc(100vw - 32px);
            padding-left: 8px;
            margin-bottom: -41px;
            z-index: 4;
            margin-top: 10px;
            position: relative;
            position: fixed;
            top: 16px;
            left: 16px;
            span {
                height: 32px;
                width: 32px;
                text-align: center;
                background-color: #39ace7;
                border-radius: 4px;
                display: block;
                line-height: 32px;
            }
        }

        img {
            width: 100%;
            height: auto;
            vertical-align: bottom;
            aspect-ratio: 16/9;
            object-fit: contain;
            border-radius: 4px;
        }
        h2 {
            margin: 8px 0 0 0;
        }
        h2 + span {
            opacity: 0.3;
            font-weight: 500;
            padding-bottom: 16px;
            display: block;
            border-bottom: 1px solid #ffff;
            margin: 0 0 12px 0;
            text-transform: capitalize;
        }
        p {
            font-size: 16px;
            opacity: 0.9;
            line-height: 1.4;
        }
    }

    .content-box {
        position: relative;
        background: rgb(35, 31, 32);
        background: linear-gradient(0deg, rgba(35,31,32,0.8337928921568627) 90%, rgba(35,31,32,0) 100%);
        z-index: 55;
        margin-top: 70vh;
        padding-top: 64px;
        padding-left: 16px;
        padding-right: 16px;
        overflow: auto;
        box-sizing: border-box;
    }
`;
