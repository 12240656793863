export enum Category {
    Mocltail = 'mocktail',
    Cocktail = 'cocktail',
    Spirits = 'spirits',
    Aperitif = 'aperitif',
    Digestif = 'digestif',
    // Shot = 'shot',
    // Wine = 'wine',
    // Beer = 'beer',
    // Cider = 'cider',
}

export type TabsData = {
    category: Category;
    id: string;
    name: string;
    description: string;
    image: string;
    video: string;
    fullImage: string;
    fullDescription: string;
};

export interface TabsProps {
    data: TabsData[];
    category: Category;
}

export interface TabsButtonsProps {
    onSelect: (category: Category) => void;
    selectedCategory: Category;
}
