import { FC } from 'react';
import { ScTabButton, ScTabs } from './styled';
import { Category, TabsButtonsProps } from '../../types/tabs';

export const TabsButtons: FC<TabsButtonsProps> = ({ onSelect, selectedCategory }) => {
    const handleClick = (category: Category) => {
        if (category === Category.Mocltail) {
            onSelect(category);
        }
        return;
    };

    return (
        <ScTabs>
            {Object.values(Category).map((category, index) => (
                <ScTabButton
                    disabled={selectedCategory === category}
                    onClick={() => handleClick(category)}
                    key={index}
                    isSoon={category !== Category.Mocltail}
                >
                    {category}
                </ScTabButton>
            ))}
        </ScTabs>
    );
};
