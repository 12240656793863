import { AppRouting } from './components/router';
import {  DataProvider } from './context/dataContext';

function App() {
    return (
        <DataProvider>
            <AppRouting />
        </DataProvider>
    );
}

export default App;
