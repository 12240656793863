import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Home } from '../../pages/Home';
import { ItemPage } from '../../pages/Item';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
    },
    {
        path: ':id',
        index: true,
        element: <ItemPage />,
    },
]);

export const AppRouting = () => {
    return <RouterProvider router={router} />;
};
