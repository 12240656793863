import styled from 'styled-components';

export const ScItemCard = styled.div`
    flex: 0 0 50%;
    max-width: calc(50% - 4px);
    padding: 8px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
    border-radius: 4px;
    border: 1px solid  rgba(0,0,0,0.05);
    

    .img-wrp {
        overflow: hidden;
        aspect-ratio: 1/1;
        width: 100%;
        border-radius: 4px;
    }

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .title{
        font-size: 14px;
        color:#231F20;
        margin-top: 8px;
        font-weight: 500;
    }

    .category{
        margin-top: 4px;
        font-size: 12px;
        text-transform: capitalize;
        color: #231F20;
        opacity: .3;
        font-weight: 700;
    }

    .button{
        padding: 4px 12px;
        border-radius: 4px;
        color:#39ACE7;
        background: #E7F3FD;
        width: max-content;
        margin: 16px 0 8px;
        font-size: 14px;
    }
`;
