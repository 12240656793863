import { FC } from 'react';
import { TabsProps } from '../../types/tabs';
import { ItemCard } from '../ItemCard';
import { ScTabContent } from './styled';

export const Tabs: FC<TabsProps> = ({ data, category: selectedCategory }) => {
    return (
        <ScTabContent>
            {data
                .filter(({ category }) => category === selectedCategory)
                .map((item) => (
                    <ItemCard {...item} key={item.id} />
                ))}
        </ScTabContent>
    );
};
