import styled, { keyframes } from 'styled-components';

const animation = keyframes`
    to{
        bottom: 100%;
    }
`;

export const ScContent = styled.div`
    height: calc(95vh - 160px);
    overflow-y: auto;
    width: calc(100vw - 32px);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
    .particle {
        animation-name: ${animation};
        animation-fill-mode: forwards;
        z-index: 99;
    }
`;
